<template>
    <section class="content">
        <div class="overlay-wrapper">
            <div class="card card-primary">
                <div class="overlay" v-if="loadingContent">
                    <h1 style="font-size: 40px">
                        Sedang diproses <i class="fas fa-sync-alt fa-spin"></i>
                    </h1>
                </div>
                <div class="card-body">
                    <span
                        style="
                            font-size: 15px;
                            font-weight: 700;
                            text-align: left;
                        "
                        >Saldo Awal : Rp
                        {{ formatPrice(jurnal.saldoawal) }}</span
                    >
                    <div class="row mt-3">
                        <div class="col-xl-12 col-md-12 flex">
                            <div class="row">
                                <div class="col-auto mb-2">
                                    <select
                                        class="form-control"
                                        @change="dataType($event)"
                                    >
                                        <option value="created_on">
                                            Dibuat Antara
                                        </option>
                                        <option value="journal_date" selected>
                                            Jurnal Antara
                                        </option>
                                    </select>
                                </div>
                                <div class="col-auto">
                                    <h4 class="mb-0">Periode</h4>
                                </div>
                                <div class="col-12 col-sm-auto">
                                    <div class="input-group mb-3">
                                        <input
                                            type="text"
                                            ref="daterange"
                                            class="form-control"
                                            style="border-right: 0"
                                        />
                                        <div class="input-group-append">
                                            <div
                                                class="input-group-text"
                                                style="background-color: #fff"
                                            >
                                                <span
                                                    ><span
                                                        class="fa fa-calendar"
                                                    ></span
                                                ></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-auto ml-auto">
                                    <div class="btn-group" ref="toolbar">
                                        <button
                                            type="button"
                                            data-action="read"
                                            class="btn btn-default"
                                        >
                                            <i class="fa fa-sync-alt"></i>
                                        </button>
                                        <button
                                            type="button"
                                            :disabled="downloading"
                                            v-on:click="downloadXlsx"
                                            data-action="export-xlsx"
                                            class="btn btn-default"
                                        >
                                            <i class="fa fa-download"></i>
                                            <span
                                                class="d-none d-md-inline-block"
                                                >Download</span
                                            >
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-2"></div>
                    <table
                        style="width: 100%"
                        class="table table-hover"
                        ref="tblsubsidiary"
                    >
                        <thead>
                            <tr>
                                <th>TANGGAL DIBUAT</th>
                                <th>TANGGAL JURNAL</th>
                                <th>NO. JURNAL</th>
                                <th>REFERENSI</th>
                                <th>DEBIT</th>
                                <th>CREDIT</th>
                                <th>SALDO</th>
                                <th>NOTE</th>
                            </tr>
                        </thead>
                        <tbody @click="handleClick"></tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { authFetch, createTable, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import Daterangepicker from "daterangepicker";
import moment from "moment";
import "daterangepicker/daterangepicker.css";

export default {
    name: "SubsidiaryLedger",
    data() {
        return {
            dt1: "",
            dt2: "",
            dateStart: "",
            dateEnd: "",
            errors: [],
            method: "",
            jurnal: {
                coa_id: "",
                coa_no: "",
                coa_name: "",
                saldoawal: "",
            },
            roles: "",
            role_id: "",
            coa_id: "",
            /* categoryGroup: [
                {id: 1, label: 'Full Shipper'},
                {id: 2, label: 'Partial Shipper'},
                {id: 3, label: 'Full BosCOD'},
                {id: 0, label: 'Semua'}
            ], */
            recon: "",
            category: "",
            data_type: "journal_date",
            downloading: false,
            loadingContent: false,
        };
    },
    created: function () {
        this.roles = this.$route.meta.roles;
        console.log("load initial data", this.$route);
        this.dateStart = moment()
            .add(-1, "M")
            .startOf("month")
            .format("YYYY-MM-DD");
        this.dateEnd = moment().format("YYYY-MM-DD");

        this.loadSaldoAwal();
    },
    methods: {
        loadSaldoAwal() {
            authFetch(
                `/ppob/subsidiary/saldo_awal?dt1=${this.dateStart}&dt2=${this.dateEnd}&data_type=${this.data_type}`
            )
                .then((res) => {
                    if (res.status === 201) {
                    } else if (res.status === 400) {
                    }
                    return res.text();
                })
                .then((json) => {
                    let data = JSON.parse(json);
                    this.jurnal.saldoawal = data.sumsaldo_awal * 1;
                });
        },
        formatPrice(param) {
            return formatCurrency(param);
        },
        dataType(event) {
            this.data_type = event.target.value;
            this.tbl.api().ajax.reload();
        },
        onPeriode() {
            this.tbl.api().ajax.reload();
        },
        handleClick(e) {
            const er = this.$refs;
            if (e.target.matches(".link-role")) {
            }
        },
        categoryChange(event) {
            this.category = event.target.value;
            this.tbl.api().ajax.reload();
        },
        reconChange(event) {
            this.recon = event.target.value;
            this.tbl.api().ajax.reload();
        },
        downloadXlsx: function (e) {
            this.downloading = true;
            var filter = {};
            filter.q = $("input[name=q]").val();
            filter.category = $("select[name=category]").val();
            filter.dt1 = this.dateStart;
            filter.dt2 = this.dateEnd;
            filter.data_type = this.data_type;
            filter.saldo_awal = this.jurnal.saldoawal;
            var data = Object.keys(filter)
                .map(
                    (key) =>
                        encodeURIComponent(key) +
                        "=" +
                        (filter[key] === false
                            ? 0
                            : encodeURIComponent(filter[key]))
                )
                .join("&");
            console.log(data);
            this.loadingContent = true;
            authFetch("/ppob/subsidiary/excell", {
                method: "POST",
                body: data,
            })
                .then((response) => {
                    if (response.status === 201) {
                    } else if (response.status === 400) {
                    } else if (response.status === 500) {
                        Swal.fire("Timeout!", ``, "error");
                        return false;
                    }

                    return response.blob();
                })
                .then((blob) => {
                    setTimeout(() => {
                        this.downloading = false;
                    }, 1000);
                    this.loadingContent = false;
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = "data.xlsx";
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });
        },
    },
    mounted() {
        const e = this.$refs;
        const self = this;
        new Daterangepicker(
            this.$refs.daterange,
            {
                startDate: moment().add(-1, "M").startOf("month"),
                endDate: moment(),
                locale: {
                    format: "DD/MM/YYYY",
                },
            },
            function (d1, d2) {
                self.dateStart = d1.format("YYYY-MM-DD");
                self.dateEnd = d2.format("YYYY-MM-DD");
                self.data_type = self.data_type;
                self.onPeriode();
                self.loadSaldoAwal();
            }
        );

        this.tbl = createTable(e.tblsubsidiary, {
            title: "",
            ajax: "/ppob/subsidiary",
            roles: this.$route.params.roles,
            toolbarEl: e.toolbar,
            selectedRowClass: "",
            serverSide: true,
            frame: false,
            scrollX: true,
            processing: true,
            autoWidth: false,
            language: {
                processing:
                    '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>',
            },
            order: [[1, "asc"]],
            paramData: function (d) {
                d.dt1 = self.dateStart;
                d.dt2 = self.dateEnd;
                d.data_type = self.data_type;
                /* d.category_payment = self.category;
            d.reconciled = self.recon; */
            },
            columns: [
                /* { data: "provider",
            sortable: false,
            render: function(data, type, row, meta){
                return "Winpay";
            } }, */
                {
                    data: "created_on",
                    class: "text-right",
                    render: function (data, type, row, meta) {
                        return !data ? "" : moment(data).format("DD/MM/YYYY");
                    },
                },
                {
                    data: "journal_date",
                    class: "text-right",
                    render: function (data, type, row, meta) {
                        return !data ? "" : moment(data).format("DD/MM/YYYY");
                    },
                },
                { data: "journal_no" },
                { data: "referensi" },
                {
                    data: "debit",
                    class: "text-right",
                    render: function (data, type, row, meta) {
                        return "Rp " + formatCurrency(data);
                        /* if (data > 0) {
                } else {
                    return "Rp 0";
                } */
                    },
                },
                {
                    data: "credit",
                    class: "text-right",
                    render: function (data, type, row, meta) {
                        return "Rp " + formatCurrency(data);
                        /* if (data > 0) {
                } else {
                    return "Rp 0";
                } */
                    },
                },
                {
                    data: "saldo",
                    class: "text-right",
                    /* render: function(data, type, row, meta) {
                return data != '...' ?  formatCurrency(data) : `<h5>${data}</h5>`;
            } */
                    render: function (data, type, row, meta) {
                        return "Rp " + formatCurrency(data);
                        /* if (data > 0) {
                } else {
                    return "Rp 0";
                } */
                    },
                },
                { data: "note" },
            ],
            filterBy: [2, 3, 7],
            rowCallback: function (row, data) {},
            initComplete: function () {
                $(".loading-overlay").removeClass("show");
            },
        });
    },
};
</script>